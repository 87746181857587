import React, { useEffect, useState } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Paper,
  Container,
  Grid,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Divider,
  Box
} from "@mui/material";
import { isValidPhoneNumber } from "libphonenumber-js";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "react-phone-input-2/lib/material.css";
import { toast } from "react-toastify";
import { apiService } from "../../services/ApiService";
import PackageSelect from "./PackageSelect";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { TrackGoogleAnalyticsEvent } from "../../utils/GoogleAnalytics.service";
import AnalyticsCategory from "../../utils/AnalyticsCategory";

// const useStyles = makeStyles((theme) => ({
//   selectedRow: {
//     backgroundColor: "#b4c9f4",
//   },
// }));

const ReNewLicense = () => {
  // const classes = useStyles();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [clientId, setClientId] = useState("");
  const [licenseKey, setLicenseKey] = useState("");
  const [registeredEmail, setRegisteredEmail] = useState("");
  const [dataFetched, setDataFetched] = useState(false);
  const [keyError, setKeyError] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const [seconds, setSeconds] = useState(10);
  const [startTimer, setStartTimer] = useState(false);

  const [activeStep, setActiveStep] = useState(0);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [isPhoneDisabled, setIsPhoneDisabled] = useState(true);
  // const [mobileCountry, setMobileCountry] = useState(null);

  const [noOfLicenses, setNoOfLicenses] = useState(1);

  // const [allProductList, setProductList] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);

  // const [allPackageList, setAllPackageList] = useState([]);

  // const [isMultiLicense, setIsMultiLicense] = useState(false);
  const [serverList, setServerList] = useState([]);
  const [currentCountry, setCurrentCountry] = useState("");
  // const [currentGeoLocation, setCurrentGeoLocation] = useState(null);

  useEffect(() => {
    getGeoInfo();

    const currentUrl = window.location.href;

    const url = new URL(currentUrl);
    const opkey = url.searchParams.get("opkey");

    sessionStorage.setItem("opkey", opkey);

    // getAllProductsList();

    return () => {};
  }, []);

  useEffect(() => {
    let timer = null;
    if (startTimer) {
      timer = setInterval(() => {
        setSeconds((prevSeconds) => {
          // Redirect when the countdown reaches 0
          if (prevSeconds === 1) {
            clearInterval(timer);
            setStartTimer(false);
            window.location.reload();
          }
          return prevSeconds - 1;
        });
      }, 1000);
    }

    return () => {
      return timer ? clearInterval(timer) : null;
    };
  }, [startTimer]);

  const getGeoInfo = async () => {
    try {
      await fetch("https://geolocation-db.com/json/")
        .then(async function (response) {
          const res = await response.json();
          // setCurrentGeoLocation(res);
          setCurrentCountry(res?.country_name || "");
        })
        .catch((err) => {
          console.log("Geo Location GET API Error :", err);
        });
    } catch (error) {
      console.log("GET >> Geo Location ::", error);
    }
  };

  let isPackageListLoding = false;
  const getAllPackagesList = (prodCode, _devices) => {
    if (isPackageListLoding) return;
    isPackageListLoding = true;
    apiService
      // .get("license/api/getAllPackageList")
      .get(`exposed/list/${prodCode}/packages?country=${currentCountry}`)
      .then((res) => res?.data || res)
      .then((response) => {
        toast.dismiss();
        if (response.resultCode > 0) {
          const list = response.data?.filter((e) => e?.isPublic);
          // setAllPackageList(list || []);

          // let _packages = allPackageList.filter((e) => e?.product == _product?._id && e?.isPublic);

          if (_devices && _devices.length > 1) {
            setNoOfLicenses(_devices.length);

            let _serverPackages = [];
            for (let i = 0; i < _devices.length; i++) {
              _serverPackages.push({
                title: `Server ${i + 1}`,
                // pack: selOpt,
                pack: null,
                packageList: list || [],
              });
            }
            setServerList(_serverPackages);
          } else if (!_devices || _devices.length <= 1) {
            setNoOfLicenses(_devices.length);

            setServerList([
              {
                title: `Server 1`,
                // pack: selOpt,
                pack: null,
                packageList: list || [],
              },
            ]);
          }
        } else {
          toast.error(response.message || "Fail");
        }
      })
      .catch((err) => {
      });
  };

  const handleNext = () => {
    let isVerified = false;
    if (activeStep == 0) {
      /** Verify User Details */

      isVerified = verifyUserDetail();
    } else if (activeStep == 1) {
      const invalidData = serverList?.find((e) => e.pack == null);

      if (invalidData) {
        toast.warn(`Please select the package for ${invalidData?.title}`);
        return;
      }
      isVerified = true;
    } else isVerified = true;
    if (isVerified) setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const verifyUserDetail = () => {
    if (!firstName || !email || !mobile || !companyName) {
      toast.warn("Please fill the required fields!");
      return false;
    }

    if (!mobile) {
      toast.error(`Please enter valid contact/mobile number!`);
      setIsPhoneDisabled(false);
      return false;
    }

    const _mobile = mobile?.includes("+") ? mobile : "+" + mobile;
    if (!isValidPhoneNumber(_mobile)) {
      toast.error(`Invalid contact/mobile number!`);
      setIsPhoneDisabled(false);
      return false;
    }

    return true;
  };

  const getExistingLicenseDetails = async () => {
    try {
      if (keyError) {
        toast.error("Please enter valid license key.");
        return;
      }
      if (emailError) {
        toast.error("Please enter valid email.");
        return;
      }
      if (licenseKey && licenseKey.trim() !== "" && registeredEmail && registeredEmail.trim() !== "") {
        await apiService
          .get(`sdk/api/keyCheck/${licenseKey}`)
          .then((res) => res?.data || res)
          .then((response) => {
            // console.log("KEY CHECK ... ", response);
            if (response.resultCode >= 0) {
              apiService
                // .get(`license/api/getFromKey/${licenseKey.trim()}`)
                // .get(`license/api/getFromKeyEmail/${licenseKey.trim()}/${registeredEmail.trim()}`)
                .get(`exposed/license/getFromKeyEmail/${licenseKey.trim()}/${registeredEmail.trim()}`)
                .then((res) => res?.data || res)
                .then((response) => {
                  toast.dismiss();
                  if (response.resultCode > 0) {
                    FormFieldsReset();
                    toast.success(response.message);
                    console.log(response.data);

                    bindData(response.data);
                    setDataFetched(true);
                  } else {
                    FormFieldsReset();
                    toast.error(response.message || "Fail");
                    setDataFetched(false);
                  }
                })
                .catch((err) => {
                });
            } else {
              toast.warning(response?.messag);
            }
          })
          .catch((err) => {
            return;
          });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const FormFieldsReset = () => {
    setFirstName(null);
    setLastName("");
    setCompanyName("");
    setEmail("");
    setMobile("");
    setSelectedProduct(null);
    setServerList([]);
    // setIsMultiLicense(false);
    setNoOfLicenses(0);
  };
  const bindData = async (licenseObject) => {
    try {
      let _client = licenseObject?.client;
      let _devices = licenseObject?.devices;
      let _product = licenseObject?.product;

      /** Set User Details */
      setClientId(_client?._id);
      setFirstName(_client?.firstName || "");
      setLastName(_client?.lastName || "");
      setCompanyName(_client?.companyName || "");
      setEmail(_client?.emailId || "");

      setMobile(_client?.mobile || "");

      /** Set Product and Server+packages details */

      setSelectedProduct(_product);

      getAllPackagesList(_product?.productCode, _devices);
    } catch (error) {
      console.log(error);
    }
  };
  let submited_req = false;
  const handleConfirm = async () => {
    let _packagesListReq = serverList.map((e) => e?.pack).filter((pack) => pack !== null);

    if (!executeRecaptcha) {
      return;
    }
    const recaptchaToken = await executeRecaptcha("renewlicense");

    let body = {
      licenseKey,
      client: clientId,
      firstName: firstName,
      lastName: lastName,
      emailId: email,
      mobile: mobile.includes("+") ? mobile : "+" + mobile,
      companyName: companyName,
      products: selectedProduct ? [selectedProduct] : null,
      packages: _packagesListReq.length ? _packagesListReq : null,
      timeStamp: new Date(),
      numberOfServers: noOfLicenses,
      recaptchaToken,
    };

    if (!body.firstName || !body.emailId) {
      toast.warn("Please fill the required fields!");
      return;
    }

    if (submited_req) return;

    submited_req = true;

    apiService
      .post("exposed/license/request/upgrade", body)
      .then((res) => res?.data || res)
      .then((response) => {
        toast.dismiss();
        
        TrackGoogleAnalyticsEvent(
          AnalyticsCategory.ReNewLicenseRequestCategory,
          AnalyticsCategory.ReNewLicenseRequestSuccess,
          window?.location?.pathname || '',
        );
        if (response.resultCode > 0) {
          toast.success(response.message);
          setStartTimer(true);
        } else {
          toast.error(response.message || "Fail");
        }
      })
      .catch((err) => {
        
        TrackGoogleAnalyticsEvent(
          AnalyticsCategory.ReNewLicenseRequestCategory,
          AnalyticsCategory.ReNewLicenseRequestFail,
          window?.location?.pathname || '',
        );
      })
      .finally(() => {
        submited_req = false;
      });
  };

  const resetKey = () => {
    setLicenseKey(null);
    setRegisteredEmail(null);
    setDataFetched(false);
    FormFieldsReset();
  };

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <>
            <Divider sx={{ my: 1 }} />
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  required
                  disabled={dataFetched}
                  label="License Key"
                  helperText="Existing License Key (which license you want to renew), format should be XXXX-XXXX-XXXX-XXXX"
                  fullWidth
                  value={licenseKey || ""}
                  error={keyError}
                  onChange={(e) => {
                    let input = e.target.value?.toUpperCase()?.replace(/[^A-Z0-9\-]/g, "");
                    let keyRegex = /^[A-Z0-9]{4}-[A-Z0-9]{4}-[A-Z0-9]{4}-[A-Z0-9]{4}$/;
                    setLicenseKey(input);

                    if (keyRegex.test(input)) {
                      setKeyError(false);
                    } else {
                      setKeyError(true);
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  required
                  disabled={dataFetched}
                  label="Registered Email"
                  helperText="Use email which is registered with the existing license."
                  fullWidth
                  value={registeredEmail || ""}
                  error={emailError}
                  onChange={(e) => {
                    let input = e.target.value
                      .replace(/[^A-Za-z0-9\s\.@-]/g, "")
                      .replace(/\s+/g, " ")
                      .replace(/^\./g, "")
                      .replace(/@+/g, "@")
                      .replace(/\.\.+/g, ".");
                    setRegisteredEmail(input);

                    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

                    if (emailRegex.test(input) || input == "") {
                      setEmailError(false);
                    } else {
                      setEmailError(true);
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} sx={{ textAlign: "center" }}>
                <Button
                  sx={{ mt: "1rem", mx: "0.3rem" }}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    getExistingLicenseDetails();
                  }}
                >
                  Fetch Details
                </Button>
                <Button
                  sx={{ mt: "1rem", mx: "0.3rem" }}
                  variant="contained"
                  color="inherit"
                  onClick={() => {
                    resetKey();
                  }}
                >
                  Reset
                </Button>
              </Grid>
            </Grid>
            {dataFetched ? (
              <>
                <Divider sx={{ my: 1 }} />
                <Typography variant="h6" align="center">
                  User Details
                </Typography>
                <Divider sx={{ my: 1 }} />
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      required
                      label="First Name"
                      fullWidth
                      value={firstName}
                      disabled
                      // onChange={(e) => setFirstName(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      label="Last Name"
                      fullWidth
                      value={lastName}
                      disabled
                      // onChange={(e) => setLastName(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      label="Email"
                      fullWidth
                      value={email}
                      disabled
                      // onChange={(e) => setEmail(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box sx={{ display: "flex", alignItems: "flex-end", marginTop: "0rem" }}>
                      <PhoneInput
                        disabled={isPhoneDisabled}
                        specialLabel="Contact Number"
                        country={"in"}
                        inputProps={{
                          name: "phoneNumber",
                          required: true,
                          autoFocus: true,
                          style: {
                            height: 56,
                            width: "100%",
                            borderColor: "#ccc",
                          },
                        }}
                        value={mobile}
                        onChange={(phoneNumber, country) => {
                          setMobile(phoneNumber);
                          // setMobileCountry(country.countryCode);
                        }}
                      />
                    </Box>{" "}
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <TextField
                      required
                      label="Company Name"
                      fullWidth
                      value={companyName}
                      disabled
                      // onChange={(e) => setCompanyName(e.target.value)}
                    />
                  </Grid>

                  {/* <Grid item xs={12} sm={6}>
                    <FormGroup sx={{ mt: 1, ml: 1 }}>
                      <FormControlLabel
                        control={
                          <Switch
                          disabled
                            value={isMultiLicense}
                            onChange={(e) => {
                              setIsMultiLicense(e.target.checked);
                              if (!e?.target?.checked) {
                                setNoOfLicenses(1);
                              }
                            }}
                          />
                        }
                        label="Multiple Licences"
                      />
                    </FormGroup>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      type="number"
                      label="Number of Devices/Servers"
                      helperText="1 to 5"
                      disabled={!isMultiLicense}
                      value={noOfLicenses}
                      onChange={(e) => {
                        let n = Number(e.target.value);
                        if (n < 1 || n > 5) {
                          toast.warn("You can select 1 to 5 servers at a time.");
                        } else {
                          setNoOfLicenses(n);
                        }
                      }}
                      min="1"
                      max="5"
                      fullWidth
                    />
                  </Grid> */}
                </Grid>
              </>
            ) : (
              <></>
            )}
          </>
        );
      case 1:
        return (
          <PackageSelect
            selectedProduct={selectedProduct}
            noOfLicenses={noOfLicenses}
            serverList={serverList}
            setServerList={setServerList}
            currentCountry={currentCountry}
            setCurrentCountry={setCurrentCountry}
          />
        );
      case 2:
        return (
          <>
            <Divider sx={{ my: 1 }} />
            <Typography variant="h6" align="center">
              Confirm Details
            </Typography>
            <Divider sx={{ my: 1 }} />
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Typography>
                  Full Name: <strong>{`${firstName} ${lastName}`}</strong>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography>
                  Email: <strong>{email}</strong>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography>
                  Mobile: <strong>{mobile?.includes("+") ? mobile : `+${mobile}`}</strong>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography>
                  Company: <strong>{companyName}</strong>
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography>
                  <strong>Product (Code):</strong> {`${selectedProduct?.productName} (${selectedProduct?.productCode})`}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography>
                  Country: <strong>{currentCountry}</strong>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  <strong>Total Required Licenses:</strong> {noOfLicenses}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Server</TableCell>
                        <TableCell>Package / Validity / Price</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {serverList &&
                        serverList?.map((e, ind) => {
                          return (
                            <TableRow key={`tr_k_${ind}`}>
                              <TableCell>{e?.title}</TableCell>
                              <TableCell>
                                {e?.pack
                                  ? `${e?.pack?.name}/${e?.pack?.durationDays} Days/ $${e?.pack?.price}`
                                  : "<No Package Selected>"}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </>
        );
      default:
        return "Unknown stepIndex";
    }
  };

  return (
    <Container component="main" maxWidth="md">
      <Paper elevation={3} style={{ padding: 20, marginTop: 20 }}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <img src="/Valydate4u.png" style={{ margin: "auto 0.5rem", width: "16rem", height: "100%" }} />
        </Box>
        <Typography variant="h4" align="center">
          Upgrade License Request
        </Typography>
        <Divider sx={{ my: 2 }} />
        {startTimer ? (
          <>
            <Typography variant="h2" color="lightgrey" align="center" sx={{ margin: "3rem" }}>
              {seconds}
            </Typography>
            <Typography variant="h5" align="center">
              Thanks for request, you will receive email soon for license renewal.
            </Typography>
          </>
        ) : (
          <>
            <Stepper activeStep={activeStep} alternativeLabel>
              <Step key="Personal Info">
                <StepLabel />
              </Step>
              {/* <Step key="Select Product">
                <StepLabel />
              </Step> */}
              <Step key="Select Package">
                <StepLabel />
              </Step>
              <Step key="Confirm">
                <StepLabel />
              </Step>
            </Stepper>
            {getStepContent(activeStep)}

            <div style={{ marginTop: 20 }}>
              {activeStep !== 0 && (
                <Button onClick={handleBack} style={{ marginRight: 10 }}>
                  Back
                </Button>
              )}
              {activeStep !== 2 ? (
                <Button disabled={!dataFetched} variant="contained" color="primary" onClick={handleNext}>
                  Next
                </Button>
              ) : (
                <Button variant="contained" color="primary" onClick={handleConfirm}>
                  Confirm and Submit
                </Button>
              )}
            </div>
          </>
        )}
      </Paper>
    </Container>
  );
};

export default ReNewLicense;
